import { Injectable , Inject} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';

@Injectable()
export class MainInterceptorInterceptor implements HttpInterceptor {

  OcpApimSubscriptionKey = environment.Ocp_Apim_Subscription_Key;
  isAuthenticated:boolean = false;
  constructor(public authStateService: OktaAuthStateService,@Inject(OKTA_AUTH) public oktaAuth: OktaAuth) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(req, next))
  }

  async getToken(){
    //return "eyJraWQiOiJCWklpbXMyai1SQWFtaUtQa0ZoVERra1FpcTJBRlA5dGVkNDlmWGx6WWhJIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkwzUGI2cTlBczhELWtFdV9OT0xMRGVjZjFHcWJOUGU5NVBTUjBrZHJqU0Uub2FyMnZiMTdlZ1JQMGE0UXIwaDciLCJpc3MiOiJodHRwczovL3J5ZGVyLm9rdGFwcmV2aWV3LmNvbS9vYXV0aDIvZGVmYXVsdCIsImF1ZCI6ImFwaTovL2RlZmF1bHQiLCJpYXQiOjE3MjU1NDcyNzAsImV4cCI6MTcyNTU1MDg3MCwiY2lkIjoiMG9hMjVlcGdhMndHNWZJZlEwaDgiLCJ1aWQiOiIwMHUyM2hnN3dudEx3UlpCNjBoOCIsInNjcCI6WyJwcm9maWxlIiwib2ZmbGluZV9hY2Nlc3MiLCJvcGVuaWQiLCJlbWFpbCJdLCJhdXRoX3RpbWUiOjE3MjU1NDcyNjgsInN1YiI6IjAwMzk5MjkwQGNvcnAucnlkZXIuY29tIiwidXBuIjoiWW9nZXNoX0t1bWFyMUByeWRlci5jb20iLCJFbWFpbCI6IllvZ2VzaF9LdW1hcjFAcnlkZXIuY29tIiwiRmlyc3ROYW1lIjoiWW9nZXNoIiwicm9sZXMiOiJTQ0FMRVVTRVIiLCJBc3NldFdvcmtzdWlkIjoiMDAzOTkyOTAiLCJMYXN0TmFtZSI6Ikt1bWFyIiwiVXNlclR5cGUiOiJDT05USU5HRU5UIiwiZW1wbG95ZWVOdW1iZXIiOiIwMDM5OTI5MCJ9.h42xFJUz3FREJsvahAMEfPvkqUIq6hiM2FMPGvuh_tiQjqyae_xgtXZKB1b-x_t0k0La7EnHzSTJO9iLEklxudUKl_b4V5yam9bgpILjAiU5JO5q2CjjwB8ohb3lQqzy9DNi6pfm7hS1Ekr6qaA1USBBMVuWtsQbroQ7VLYOz6peclZ5lc7cIsCnwXV_OcobCqivzQtbHo-OqxrJ8UooX8f-VuFw0av7n_Luxt-qSUXw4C3x26g_0bBBfUhjVxIdtoTuEUfMxjXx8yYSzbO6vEKX9PLqU0tSzqVND_JjGjl55jEudPZquk1N4K1ZiLxYyMquBiHiQtyYrWqADyg0LA";
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();
    if (this.isAuthenticated) {
      const accessToken = await this.oktaAuth.getAccessToken();
      return accessToken?`${accessToken}`:'un-authenticated';
    }else{
      return 'un-authenticated';
    }
  }

  async handleAccess(req: HttpRequest<any>, next: HttpHandler): Promise<any> {
    let newHeader = req.headers;
    newHeader = newHeader.append('Ocp-Apim-Subscription-Key', this.OcpApimSubscriptionKey);
    newHeader = newHeader.append('Token', await <any>this.getToken());
    const authreq = req.clone({ headers: newHeader });
    console.log('MainInterceptorInterceptor');
    return next.handle(authreq).pipe(
        tap(evt => {
          // modify here
          return evt;
        }),
        catchError((error: any) => {
          if (error && error.status) {
            if (error.status == 401) {
              //this.router.navigate(['/']);
            }else{
              return <any>throwError(error.error);
            }
          } else {
            return <any>throwError(error);
          }
        })
    ).toPromise();
    //return next.handle(authreq).pipe(map(res=> res)).toPromise();	
  }

}
