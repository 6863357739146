import { environment } from '../../environments/environment';

let redirectUri = window.location.origin+'/login/callback';

const clientId = environment.clientId;
const issuer = environment.issuer;
const scope = ['openid', 'profile', 'email', 'offline_access'];

export default {
  oidc: {
    clientId: clientId,
    issuer: issuer,
    redirectUri: redirectUri,
    scopes: scope,
    pkce: true,
    testing: {
      disableHttpsCheck: true
    }
  },
  resourceServer: {
    messagesUrl: 'https://localhost:8000/api/messages',
  },
};

