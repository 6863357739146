export const MODULES_PERMISSIONS_STORE = [
    {
        'moduleName': 'home',
        'permissions': [
            'MODULE_ACCESS'
        ]
    },
    {
        'moduleName': 'dashboard',
        'permissions': [
            'MODULE_ACCESS',
            'FILTERS_ACCESS',
            'TABLEDATA_ACCESS',
            'PTA-REQUEST-DETAILS_ACCESS',
            'PTA-REQUEST-DETAILS-PREMIUMCHECKLIST-LINK_ACCESS'
        ]
    },
    {
        'moduleName': 'ptaconfig',
        'permissions': [
            'MODULE_ACCESS',
            'PARTS-UPLOAD_ACCESS',
            'DUNS-UPLOAD_ACCESS',
            'PARTS-DOWNLOAD_ACCESS',
            'DUNS-DOWNLOAD_ACCESS'
        ]
    },
    {
        'moduleName': 'newptaforms',
        'permissions': [
            'MODULE_ACCESS',
            'CREATE-NEW-PTA-REQUEST_ACCESS'
        ]
    },
    {
        'moduleName': 'premiumchecklist',
        'permissions': [
            'MODULE_ACCESS',
            'EMERGENCY-AUTHORIZATION_ACCESS',
            'PTA-APPROVE_ACCESS',
            'PTA-REJECT_ACCESS',
            'EVENTLOG-VIEW_ACCESS',
            'DOWNLOAD-PDF_ACCESS',
            'PTA-RETRIGGEREMAIL_ACCESS'
        ]
    },
    {
        'moduleName': 'usermgmt',
        'permissions': [
            'MODULE_ACCESS',
            "CREATE",
            "UPDATE",
            "DELETE",
            "SEARCH",
            "ACTIVATEDEACTIVATE"
        ]
    }
]

export const MODULES_PERMISSIONS_STORE_MAP = [
    {
        'moduleName': 'home',
        'moduleName_API': 'Home',
        'permissions': {
            'MODULE_ACCESS': 'View'
        }
    },
    {
        'moduleName': 'dashboard',
        'moduleName_API': 'PTA_Existing_Requests',
        'permissions': {
            'MODULE_ACCESS': 'View',
            'FILTERS_ACCESS': 'NA',
            'TABLEDATA_ACCESS': 'NA',
            'PTA-REQUEST-DETAILS_ACCESS': 'NA',
            'PTA-REQUEST-DETAILS-PREMIUMCHECKLIST-LINK_ACCESS': 'PTA_Request_Details_PremiumCheckList_Link'
        }
    },
    {
        'moduleName': 'ptaconfig',
        'moduleName_API': 'PTA_Configuration',
        'permissions': {
            'MODULE_ACCESS': 'View',
            'PARTS-UPLOAD_ACCESS': 'NA',
            'DUNS-UPLOAD_ACCESS': 'NA',
            'PARTS-DOWNLOAD_ACCESS': 'NA',
            'DUNS-DOWNLOAD_ACCESS': 'NA'
        }
    },
    {
        'moduleName': 'newptaforms',
        'moduleName_API': 'PTA_New_Request',
        'permissions': {
            'MODULE_ACCESS': 'View',
            'CREATE-NEW-PTA-REQUEST_ACCESS': 'PTA_Request_Create'
        }
    },
    {
        'moduleName': 'premiumchecklist',
        'moduleName_API': 'PremiumCheckList',
        'permissions': {
            'MODULE_ACCESS': 'View',
            'EMERGENCY-AUTHORIZATION_ACCESS': 'NA',
            'PTA-APPROVE_ACCESS': 'PTA_Request_Approve',
            'PTA-REJECT_ACCESS': 'PTA-Request_Reject',
            'EVENTLOG-VIEW_ACCESS': 'PTA_Request_ViewEventLogs',
            'DOWNLOAD-PDF_ACCESS': 'PTA_Request_DownloadPDF',
            'PTA-RETRIGGEREMAIL_ACCESS':'NA'
        }
    },
    {
        'moduleName': 'usermgmt',
        'moduleName_API': 'UserManagement',
        'permissions': {
            'MODULE_ACCESS': 'View',
            'CREATE': 'Create',
            'UPDATE': 'Update',
            'DELETE': 'Delete',
            'SEARCH': 'Search',
            'ACTIVATEDEACTIVATE': 'Update_Status'
        }
    },
    {
        'moduleName': 'lps-records',
        'moduleName_API': 'Update_LPS_Routes',
        'permissions': {
            'MODULE_ACCESS': 'View',
            "FILTER_BY_VIEW": "Filter_by_View",
            "FILTER_BY_EDIT": "Filter_by_Edit",
            "UPDATE_LPS_ROUTES_GRID_VIEW": "Update_LPS_Routes_Grid_View",
            "UPDATE_LPS_ROUTES_GRID_EDIT": "Update_LPS_Routes_Grid_Edit"
        }
    },
    {
        'moduleName': 'update-lps-records',
        'moduleName_API': 'Confirm_LPS_Routes',
        'permissions': {
            'MODULE_ACCESS': 'View',
            "CONFIRM_LPS_ROUTES_GRID_VIEW": "Confirm_LPS_Routes_Grid_View",
            "CONFIRM_LPS_ROUTES_GRID_EDIT": "Confirm_LPS_Routes_Grid_Edit"
        }
    }
]