import { environment } from '../../environments/environment';

const API_PRODUCTION_URL = environment.api_domain_url;

export const getRetrigger_API = `${API_PRODUCTION_URL}/ptaemailtrigger`;
export const uploadFileParts_API = `${API_PRODUCTION_URL}/parts`;
export const uploadFileDuns_API = `${API_PRODUCTION_URL}/duns`;
export const downloadFile_API = `${API_PRODUCTION_URL}/templates?TemplateName=`;
export const getLookUp_API = `${API_PRODUCTION_URL}/lookups`;
export const getDashboardTableData_API = `${API_PRODUCTION_URL}/ptadashboards`;
export const getPremiumCheckListData_API = `${API_PRODUCTION_URL}/ptarequests`;
export const getPremiumCheckListapproveRejectSave_API = `${API_PRODUCTION_URL}/ptarequests`;
export const getPremimumCheckListEventLogs_API = `${API_PRODUCTION_URL}/ptaeventlogs`;
export const getPremimumCheckListPDF_API = `${API_PRODUCTION_URL}/ptadocuments`;
export const getPremimumCheckListCreatePTARequest_API = `${API_PRODUCTION_URL}/ptarequests`;
export const getParts_API = `${API_PRODUCTION_URL}/parts`;
export const getDUNS_API = `${API_PRODUCTION_URL}/duns`;
export const getEmail_API = `${API_PRODUCTION_URL}/users`;

export const searchUser_API = `${API_PRODUCTION_URL}/users`;
export const updateUser_API = `${API_PRODUCTION_URL}/users`;
export const createUser_API = `${API_PRODUCTION_URL}/users`;
export const statusChangeUser_API = `${API_PRODUCTION_URL}/userstatus`;

export const accessPermissions_API = `${API_PRODUCTION_URL}/useraccess`;
