import { Component, Inject } from '@angular/core';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { MainServiceService } from './services/main-service.service';
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  env = environment.env_name;
  title = 'PROJECT-ADEPT';

  notification: boolean = false;
  notificationLabel: string = 'Error';
  notificationTheme: any = "" || "default" || "error" || "warning" || "success";

  loggedInUser:any;
  isAuthenticated: boolean = false;
  constructor(private service: MainServiceService,public authStateService: OktaAuthStateService, @Inject(OKTA_AUTH) public oktaAuth: OktaAuth) {
    if(window.location.href.indexOf('localhost')==-1){
      console.log = ()=>{};
    }
  }

  ngOnInit(): void {
    console.log('%c ENV: '+ this.env, 'background: green; color: white');
    //notification reciever
    this.service.getLiveBroadCastData$.subscribe((data: any) => {
      console.log('notification recieved', data);
      if (data['broadcastedFor'] == 'notification') {
        this.showNotification(data['text'], data['theme']);
      }
    });

    this.getLoggedInUserInfo();
    
  }

  showNotification(notificationLabel: string, notificationTheme: any = 'error') {
    this.notificationLabel = notificationLabel;
    this.notificationTheme = notificationTheme;
    this.notification = true;

    setTimeout(() => {
      this.notification = false;
    }, 10000);
  }

  notificationAction(event: any, notificationCall: string) {
    console.log('notificationCall=> ', event);
    this.notification = false;
  }


  async login() {
    await this.oktaAuth.signInWithRedirect({ originalUri: '/' });
  }

  async logout() {
    await this.oktaAuth.signOut();
  }

  async getLoggedInUserInfo(){
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();
    if (this.isAuthenticated) {
      const userClaims = await this.oktaAuth.getUser();
      this.loggedInUser = Object.entries(userClaims).map(entry => ({ claim: entry[0], value: entry[1] }));
      //console.log('loggedInUser',this.loggedInUser)
    }else{
      console.log('not authenticated');
      //this.login();
    }
    
  }


}
