import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Observable, of, map, take, tap, debounceTime, Subject, BehaviorSubject } from 'rxjs';
import { text } from '@fortawesome/fontawesome-svg-core';
import * as localStore from '../model/localStore';
import * as API_STORE from '../model/apiStore';
import * as ACCESS_STORE from '../model/userAccess';

@Injectable({
  providedIn: 'root'
})
export class MainServiceService {

  developmentMode: boolean = true;

  PERMISSIONS_STORE: any;

  getLookUp_API;
  getDashboardTableData_API;
  getPremiumCheckListData_API;
  getPremiumCheckListapproveRejectSave_API;
  getPremimumCheckListEventLogs_API;
  getPremimumCheckListPDF_API;
  getPremimumCheckListCreatePTARequest_API;
  getParts_API;
  getDUNS_API;
  getEmail_API;

  uploadFileParts_API;
  uploadFileDuns_API;
  downloadFile_API;

  searchUser_API;
  updateUser_API;
  createUser_API;
  getRetrigger_API;
  statusChangeUser_API;

  accessPermissions_API;

  tempStoreVar: any = undefined;
  public progressBarEvent: EventEmitter<boolean> = new EventEmitter();
  constructor(private http: HttpClient) {

    let devpreviewMode = window.location.href.indexOf('localhost') == -1;
    if (devpreviewMode) {
      this.developmentMode = false;
    }

    this.getRetrigger_API = API_STORE.getRetrigger_API;
    this.getLookUp_API = API_STORE.getLookUp_API;
    this.getDashboardTableData_API = API_STORE.getDashboardTableData_API;
    this.getPremiumCheckListData_API = API_STORE.getPremiumCheckListData_API;
    this.getPremiumCheckListapproveRejectSave_API = API_STORE.getPremiumCheckListapproveRejectSave_API;
    this.getPremimumCheckListEventLogs_API = API_STORE.getPremimumCheckListEventLogs_API;
    this.getPremimumCheckListPDF_API = API_STORE.getPremimumCheckListPDF_API;
    this.getPremimumCheckListCreatePTARequest_API = API_STORE.getPremimumCheckListCreatePTARequest_API;
    this.getParts_API = API_STORE.getParts_API;
    this.getDUNS_API = API_STORE.getDUNS_API;
    this.getEmail_API = API_STORE.getEmail_API;

    this.uploadFileParts_API = API_STORE.uploadFileParts_API;
    this.uploadFileDuns_API = API_STORE.uploadFileDuns_API;
    this.downloadFile_API = API_STORE.downloadFile_API;

    this.searchUser_API = API_STORE.searchUser_API;
    this.updateUser_API = API_STORE.updateUser_API;
    this.createUser_API = API_STORE.createUser_API;
    this.statusChangeUser_API = API_STORE.statusChangeUser_API;

    this.accessPermissions_API = API_STORE.accessPermissions_API;

  }

  areEqualArray(arr1: [], arr2: []) {
    let N = arr1.length;
    let M = arr2.length;

    if (N != M) {
      return false;
    }

    arr1.sort();
    arr2.sort();

    for (let i = 0; i < N; i++) {
      if (arr1[i] != arr2[i]) {
        return false;
      }
    }
    return true;
  }

  private dataSubject = new BehaviorSubject<any>('initial');
  getLiveBroadCastData$ = this.dataSubject.asObservable();

  broadCastData(data: any) {
    this.dataSubject.next(data);
  }

  loaderShow(enable: boolean = false) {
    let data = {
      'broadcastedFor': 'loader',
      'enable': enable
    }
    this.broadCastData(data);
  }

  notificationControl(textToShow: string = '', theme: any = '') {
    let data = {
      'broadcastedFor': 'notification',
      'text': textToShow,
      'theme': theme
    }

    if (textToShow && theme) {
      console.log('notification pushed');
      this.broadCastData(data);
    }
  }

  errorNotificationShow(textToShow: string = '') {
    let errorText = textToShow || 'API failed!';
    this.notificationControl(errorText, 'error');
  }

  callRetriggerEmail(PTANumber:string){
    let url = this.getRetrigger_API + `?ptaNumber=${PTANumber}`;

    if (this.developmentMode) {
      return of(this.offlineStorage('retrigger_API'));
    }
    return this.http.post(url, null);
  }

  getLookUp() {
    let url = this.getLookUp_API;

    if (this.developmentMode) {
      return of(this.offlineStorage('getlookup'));
    }
    return this.http.get(url);
  }
  getDashboardTableData(payload: any) {
    let node = [];
    let url = '';
    if (payload) {
      node = Object.keys(payload).filter(t => payload[t] != undefined && payload[t] != '');
    }
    if (node.length > 0) {
      if (node.length == 1) {
        url = this.getDashboardTableData_API + `?pageNumber=${payload.pageNumber}`;
      } else {
        let queryStringArray: any = [];
        Object.keys(payload).map((x, i) => {
          if (payload[x] != undefined && payload[x] != '') {
            queryStringArray.push(`${x}=${payload[x]}`);
          }
        });
        let queryString = '?' + queryStringArray.join('&');
        url = this.getDashboardTableData_API + `${queryString}`;
        console.log('Dashboard API payload', queryString);
      }
    }

    if (this.developmentMode) {
      return of(this.offlineStorage('dashboardtable'));
    }
    return this.http.get(url);
  }

  getPremiumCheckListData(payload: any) {
    let PTANumber = payload;
    // if (PTANumber == '' && PTANumber == undefined) {
    //   this.errorNotificationShow('PremiumCheckList: PTANumber not found');
    // }

    let url = this.getPremiumCheckListData_API + `?PTANumber=${PTANumber}`;

    if (this.developmentMode) {
      return of(this.offlineStorage('premiumCheckListData'));
    }
    return this.http.get(url);

  }

  getPremiumCheckListapproveRejectSave(payload: any) {
    let url = this.getPremiumCheckListapproveRejectSave_API;
    if (this.developmentMode) {
      return of(this.offlineStorage('getPremiumCheckListapproveRejectSave'));
    }
    return this.http.put(url, payload);
  }

  getPremimumCheckListEventLogs(Id: string) {
    let url = this.getPremimumCheckListEventLogs_API + `?Id=${Id}`;
    if (this.developmentMode) {
      return of(this.offlineStorage('getPremimumCheckListEventLogs'));
    }
    return this.http.get(url);
  }

  getPremimumCheckListPDF(fileName: string) {
    let url = this.getPremimumCheckListPDF_API + `?fileName=${fileName}`;
    if (this.developmentMode) {
      return of(this.offlineStorage('getPremimumCheckListPDF'));
    }
    return this.http.get(url);
  }

  getPremimumCheckListCreatePTARequest(payload: any) {

    let url = this.getPremimumCheckListCreatePTARequest_API;
    if (this.developmentMode) {
      return of(this.offlineStorage('getPremimumCheckListCreatePTARequest'));
    }
    return this.http.post(url, payload);
  }

  getParts(partNumber: string, plantCode: string) {

    let url = this.getParts_API + `?partNumber=${partNumber}&plantCode=${plantCode}`;

    if (this.developmentMode) {
      return of(this.offlineStorage('getParts'));
    }
    return this.http.get(url);
  }

  getDUNS(dunsNumber: string) {

    let url = this.getDUNS_API + `?dunsNumber=${dunsNumber}`;

    if (this.developmentMode) {
      return of(this.offlineStorage('getDUNS'));
    }
    return this.http.get(url);
  }

  getEmailsLookUp(name: string) {

    let url = this.getEmail_API + `?name=${name}`;

    if (this.developmentMode) {
      return of(this.offlineStorage('getEmailsLookUp'));
    }
    return this.http.get(url);
  }

  uploadFile(fileToUpload: File, urlSelection: string) {
    let url = '';
    if (urlSelection.indexOf('parts') != -1) {
      url = this.uploadFileParts_API;
    } else if (urlSelection.indexOf('duns') != -1) {
      url = this.uploadFileDuns_API;
    }
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(url, formData);
  }

  downloadFile(dunsORparts: string) {
    let url = this.downloadFile_API + `${dunsORparts}`;
    return this.http.get(url);
  }

  getSetUserData(command: string, payload: string) {
    if (command == 'getuser') {
      let url = this.searchUser_API + `?SearchText=${payload}`;
      return this.http.get(url);
    }
    if (command == 'updateuser') {
      let url = this.updateUser_API;
      return this.http.put(url, payload);
    }
    if (command == 'createuser') {
      let url = this.createUser_API;
      return this.http.post(url, payload);
    }
    return;
  }

  statusChangeUser(payload:string){
    let url = this.statusChangeUser_API;

    if (this.developmentMode) {
      return of(this.offlineStorage('statusChangeUser'));
    }
    return this.http.put(url, payload);
  }

  callAccessPermissions() {
    let url = this.accessPermissions_API;
    if (this.developmentMode) {
      return of(this.offlineStorage('accessPermissions'));
    }
    return this.http.get(url);
  }

  getAllAccess(api_permissions: any) {
    console.log('api_permissions', api_permissions);
    //let PERMISSIONS_STORE = ACCESS_STORE.MODULES_PERMISSIONS_STORE;
    let PERMISSIONS_STORE_MAP = ACCESS_STORE.MODULES_PERMISSIONS_STORE_MAP;
    // filter and modify permissions and save this  PERMISSIONS_STORE as localStorage.
    let permissions: any = [];
    PERMISSIONS_STORE_MAP.forEach((pageModule: any) => {
      let API_module = api_permissions.find((x: any) => x.resource === pageModule.moduleName_API);
      if (API_module == undefined) {
        return;
      }

      let permissionsArray: any = [];
      API_module.permissions.map((k: any) => {

        let key: any = Object.entries(pageModule.permissions).find((p: any) => p[1] === k);
        if (key) {
          key = key[0];
          permissionsArray.push(key);
        }

      });
      let NA_Keys = Object.entries(pageModule.permissions).filter((p: any) => p[1] === 'NA').map((x: any) => x[0]);
      //console.log('permissionsArray',permissionsArray);
      permissions.push({
        'moduleName': pageModule.moduleName,
        'permissions': [
          ...permissionsArray, ...NA_Keys
        ]
      })
    })
    localStorage.removeItem('PAGE_PERMISSIONS_STORE');
    localStorage.setItem('PAGE_PERMISSIONS_STORE', JSON.stringify(permissions));
    let InactiveUser = permissions.filter((x:any)=> x.permissions.includes('MODULE_ACCESS'));
    if(InactiveUser.length == 1 && InactiveUser[0] && InactiveUser[0].moduleName == 'home'){
      localStorage.setItem('user-status', 'Inactive');
    }else{
      localStorage.setItem('user-status', 'Active');
    }

    //localStorage.setItem('PAGE_PERMISSIONS_STORE', JSON.stringify(PERMISSIONS_STORE));
    console.log('filtered permissions', permissions);
  }

  getPageAccess(moduleName: string) {
    this.PERMISSIONS_STORE = localStorage.getItem('PAGE_PERMISSIONS_STORE');
    let modulePermissions;
    let result: any = {};
    if (!this.PERMISSIONS_STORE) {
      return result;
    }
    this.PERMISSIONS_STORE = JSON.parse(this.PERMISSIONS_STORE);
    modulePermissions = this.PERMISSIONS_STORE.find((module: any) => module.moduleName === moduleName);
    modulePermissions = modulePermissions ? modulePermissions['permissions'] : '';

    if (modulePermissions) {
      Object.values(modulePermissions).map((x: any) => result[x] = true);
    }

    return result;
    // {
    //   MODULE_ACCESS:true
    // }
  }

  deepClone(obj: any) {
    let clone: any = structuredClone(obj);
    return clone;
  }

  deepCloneCustom(obj: any) {
    var result: any = obj.constructor.toString().indexOf('Array') == -1 ? {} : [];
    for (let i in obj) {
      result[i] = typeof (obj[i]) == 'object' ? this.deepClone(obj[i]) : obj[i];
    }
    return result;
  }

  tempStore(command: string, val: any = ''): any {
    if (command == 'save') {
      this.tempStoreVar = val;
    } else if (command == 'get') {
      let _this = this;
      setTimeout(_ => {
        _this.tempStore('clear');
      }, 500);

      return this.tempStoreVar;
      //return localStore.samplePremiumChekListDataNewPTARequest_DummyData;

    } else if (command == 'clear') {
      this.tempStoreVar = undefined;
    }
  }

  offlineStorage(par: string) {
    let data: any;
    if(par == 'retrigger_API'){
      data = localStore.retrigger_API;
    }
    if (par == 'getPremimumCheckListPDF') {
      data = localStore.getPremimumCheckListPDF;
    }
    if (par == 'dashboardtable') {
      data = localStore.dashboardtable;
      data.dashBoardDetail.dashBoards = [...data.dashBoardDetail.dashBoards, ...data.dashBoardDetail.dashBoards, ...data.dashBoardDetail.dashBoards, ...data.dashBoardDetail.dashBoards];
    }

    if (par == 'getDUNS') {
      data = localStore.getDUNS;
    }

    if (par == 'getParts') {
      data = localStore.getParts;

    }
    if (par == 'getlookup') {
      data = localStore.getlookup;
    }

    if (par == 'getEmailsLookUp') {
      data = localStore.getEmailsLookUp;
    }

    if (par == 'premiumCheckListData') {
      data = localStore.premiumCheckListData;

      let random_boolean = Math.random() < 0.5;
      data.premiumCheckList.requestStatus = random_boolean ? "Approved" : "Created";
    }

    if (par == 'getPremiumCheckListapproveRejectSave') {
      data = localStore.getPremiumCheckListapproveRejectSave;
    }

    if (par == 'getPremimumCheckListEventLogs') {
      data = localStore.getPremimumCheckListEventLogs;
    }

    if (par == 'getPremimumCheckListCreatePTARequest') {
      data = localStore.getPremimumCheckListCreatePTARequest;
    }

    if (par == 'accessPermissions') {
      data = localStore.accessPermissions;
    }

    if (par == 'statusChangeUser') {
      data = localStore.statusChangeUser;
    }
    

    return (data);
  }


}
